@tailwind base;
@tailwind components;
@tailwind utilities;

.card {
  @apply border bg-red-500 rounded overflow-hidden shadow-md relative hover:shadow-lg;
}

.badge {
  @apply text-yellow-500 bg-yellow-100 text-xs uppercase font-bold rounded-full p-2 absolute top-0 right-0 mr-4 mt-4 inline-block;
}

.btn {
  @apply text-yellow-200 font-bold rounded-full py-2 px-3 uppercase cursor-pointer tracking-wider border-yellow-200 border-2 hover:bg-yellow-200 hover:text-red-500;
}

.navbar .links {
  margin-left: auto;
}

.text-label {
  @apply block text-sm font-bold mb-2 mt-5 text-white;
}

.text-input {
  @apply shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none;
}

svg {
  display: inline-block;
}

.recipe-body {
  white-space: pre-wrap;
}